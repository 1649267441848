import React, { useMemo } from "react"

import Head from "decentraland-gatsby/dist/components/Head/Head"
import {
  preloadAs,
  preloadType,
} from "decentraland-gatsby/dist/components/Head/Preload"
import Divider from "decentraland-gatsby/dist/components/Text/Divider"
import useFeatureFlagContext from "decentraland-gatsby/dist/context/FeatureFlag/useFeatureFlagContext"
import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"
import { Loader } from "decentraland-ui/dist/components/Loader/Loader"

import { LandingAbout } from "../components/Landing/LandingAbout/LandingAbout"
import { LandingBannerCTA } from "../components/Landing/LandingBannerCTA"
import { LandingBlogFeed } from "../components/Landing/LandingBlogFeed/LandingBlogFeed"
import { LandingEventsPlacesFeed } from "../components/Landing/LandingEvents&PlacesFeed/LandingEvents&PlacesFeed"
import LandingFaqs from "../components/Landing/LandingFaqs/LandingFaqs"
import { LandingFooter } from "../components/Landing/LandingFooter/LandingFooter"
import { LandingFooterV2 } from "../components/Landing/LandingFooterV2"
import LandingHero from "../components/Landing/LandingHero/LandingHero"
import { LandingHeroSlideAlignment } from "../components/Landing/LandingHero/LandingHeroSlide"
import { LandingHeroV2 } from "../components/Landing/LandingHeroV2"
import { MarketPlace } from "../components/Landing/LandingMarketPlaceFeed/LandingMarketPlaceFeed"
import { LandingMissions } from "../components/Landing/LandingMissions/LandingMissions"
import { LandingMissionsV2 } from "../components/Landing/LandingMissionsV2"
import { LandingSocialProof } from "../components/Landing/LandingSocialProof"
import { LandingTextMarquee } from "../components/Landing/LandingTextMarquee"
import { LandingTrending } from "../components/Landing/LandingTrending/LandingTrending"
import { LandingWhatsHot } from "../components/Landing/LandingWhatsHot"
import { LandingWorlds } from "../components/Landing/LandingWorlds/LandingWorlds"
import Layout from "../components/Layout/Layout"
import useLandingContentfulQuery from "../hooks/useLandingContentfulQuery"
import forward from "../images/forward.svg"
import { FeatureFlag } from "../modules/ff"
import { SectionViewedTrack, SegmentEvent } from "../modules/segment"

import "./index.css"

export enum Feed {
  Scenes,
  Events,
  Adventures,
  Multiplayer,
}

export default function IndexPage(props: any) {
  const l = useFormatMessage()

  const [ff, { loaded: featureFlagsLoaded, loading: featureFlagsLoading }] =
    useFeatureFlagContext()

  const landingContentful = useLandingContentfulQuery()

  const slides = useMemo(() => {
    return landingContentful.hero.list.map((entry) => ({
      ...entry,
      alignment: LandingHeroSlideAlignment.LEFT,
    }))
  }, [landingContentful.hero])

  if (!featureFlagsLoaded || featureFlagsLoading) {
    return <Loader active size="large" />
  }
  if (ff.flags[FeatureFlag.RedesignHomepage]) {
    return (
      <Layout
        fluid
        {...props}
        className={TokenList.join([props.className, "layout__navbar--fixed"])}
      >
        <Head
          title={l("index.social.title") || ""}
          description={l("index.social.description") || ""}
          image={
            landingContentful.hero.list.find((entry) => entry.id === "main")
              ?.imageLandscape.url
          }
        >
          <link
            rel="preload"
            href={forward}
            as={preloadAs(forward) || "image"}
            type={preloadType(forward)}
          />
          <link
            rel="preload"
            href={landingContentful.herov2.imageLandscape.url}
            as="image"
            type="image/jpeg"
          />
          <link
            rel="preload"
            href={landingContentful.about?.imageLandscape.url}
            as={
              preloadAs(landingContentful.about?.imageLandscape.url) || "image"
            }
            type={preloadType(landingContentful.about?.imageLandscape.url)}
          />
        </Head>
        <LandingHeroV2 hero={landingContentful.herov2} />
        <LandingMissionsV2 missions={landingContentful.missionsv2} />
        <LandingBannerCTA
          {...landingContentful.createAvatarBanner}
          eventPlace={SegmentEvent.CreateYourAvatar}
        />
        <LandingWhatsHot list={landingContentful.whatsHot.list} />
        <LandingTextMarquee text={landingContentful.textMarquee.text} />
        <LandingSocialProof socialProof={landingContentful.socialProof} />
        <LandingBannerCTA
          {...landingContentful.startExploringBanner}
          eventPlace={SegmentEvent.GoToExplorer}
        />
        <LandingFooterV2 />
      </Layout>
    )
  }

  return (
    <Layout
      fluid
      {...props}
      className={TokenList.join([props.className, "layout__navbar--fixed"])}
    >
      <Head
        title={l("index.social.title") || ""}
        description={l("index.social.description") || ""}
        image={
          landingContentful.hero.list.find((entry) => entry.id === "main")
            ?.imageLandscape.url
        }
      >
        <link
          rel="preload"
          href={forward}
          as={preloadAs(forward) || "image"}
          type={preloadType(forward)}
        />
        <link
          rel="preload"
          href={
            landingContentful.hero.list.find((entry) => entry.id === "main")
              ?.imageLandscape.url
          }
          as="image"
          type="image/jpeg"
        />
        <link
          rel="preload"
          href={landingContentful.about?.imageLandscape.url}
          as={preloadAs(landingContentful.about?.imageLandscape.url) || "image"}
          type={preloadType(landingContentful.about?.imageLandscape.url)}
        />
      </Head>

      <LandingHero slides={slides} />

      <LandingEventsPlacesFeed />

      <LandingBlogFeed />

      <LandingAbout about={landingContentful.about} />

      <LandingMissions missions={landingContentful.missions} />

      <Divider />

      <MarketPlace />

      <LandingTrending trendings={landingContentful.trending} />

      <LandingWorlds worlds={landingContentful.worlds} />

      <LandingFaqs
        faqs={landingContentful.faq}
        sectionViewed={SectionViewedTrack.LandingFaqs}
      />

      <LandingFooter footer={landingContentful.footer} />
    </Layout>
  )
}
